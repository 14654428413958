<template>
  <div>
    <v-dialog
      v-model="show"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >

          <v-toolbar-title>Document Properties</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="cancel"
            >
              Cancel
            </v-btn>
            <v-btn
              dark
              text
              @click="save"
            >
              Save My Changes
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-form class="ma-15"
                ref="form"
                v-model="valid"
                lazy-validation>
          <v-row>
            <v-col>
              <v-text-field v-model="document.name" label="Name" :rules="[v => !!v || 'Name is required']"></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <editor v-model="document.content"  placeholder="Document Text" :validation-message="editorValidationMessage"></editor>
            </v-col>
          </v-row>
        </v-form>



      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Editor from "@/components/text/Editor";

export default {
  name: "TextDocument",
  components:{Editor},
  props:['show', 'document'],
  data(){
    return {
      valid: true,
      editorValidationMessage: '',
    }
  },
  methods:{
    save(){
      let formValid = this.validate();

      if(formValid){
        this.$emit('save', document);
      }

    },
    cancel(){
      this.$refs.form.reset();
      this.editorValidationMessage = '';
      this.$emit('cancel');
    },
    validate(){
      const editorValid = this.document.content !== undefined
        && this.document.content.trim() !== ''
        && this.document.content.trim() !== '<p></p>';

      if(!editorValid){
        this.editorValidationMessage = 'Document text is required';
      }

      let formValid = this.$refs.form.validate();
      return editorValid && formValid;
    }

  }

}
</script>

<style scoped>

</style>

<template>
  <div>
    <tiptap-vuetify
      v-model="desc"
      :extensions="extensions"
      :placeholder="placeholder"
      @input="update"
    >
      <div slot="footer" v-if="validationMessage !== ''" class="ml-1">
        <div class="v-messages theme--light error--text" role="alert">
          <div class="v-messages__wrapper">
            <div class="v-messages__message">{{validationMessage}}</div>
          </div>
        </div>
      </div>
    </tiptap-vuetify>
  </div>
</template>

<script>

import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule } from 'tiptap-vuetify';

export default {
  name: "Editor",
  props:['placeholder', 'content', 'validationMessage'],
  components: { TiptapVuetify },
  methods:{
    update(){
      const vm = this;
      this.$emit('updated',vm.desc);
    }
  },
  model:{
    prop: 'content',
    event: 'updated'
  },
  mounted(){
    this.desc = this.content;
  },
  watch:{
    content: function(nv, ov){
      this.desc =  nv;
    }
  },
  data: vm => ({
    desc: '',
    extensions: [
      Bold,
      Underline,
      Italic,
      Blockquote,
      Link,
            Strike,

      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [1, 2, 3]
        }
      }],

      HorizontalRule,
      Paragraph,
      HardBreak
    ],
    // starting editor's content

  })
}

</script>

<style scoped>

</style>

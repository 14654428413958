<template>
  <div>

    <v-menu top v-if="!hideTypes">
      <template v-slot:activator="{on, attrs}">
        <v-btn
          color="primary"

               v-bind="attrs"
               v-on="on">{{buttonText}}</v-btn>
      </template>

      <v-list>
        <v-list-item @click="clicked">
          <v-list-item-title>File (image, video, document, etc)</v-list-item-title>
        </v-list-item>

        <v-list-item @click="addText">
          <v-list-item-title>Text/Link</v-list-item-title>
        </v-list-item>
      </v-list>

    </v-menu>
    <v-btn v-else color="primary" @click="clicked" :loading="loading">
      <slot></slot>
      &nbsp;
      {{buttonText}}
    </v-btn>


    <input ref="filebox" type="file" multiple name="photos"  @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
           accept="image/*,application/*" class="input-file">
  </div>
</template>

<script>
import httpClient from "@/plugins/axios";
import {mapGetters} from "vuex";
export default {
  name: "FileUploadButton",
  props:{
    buttonText: {
      type: String,
      default: 'Add Document'
    },
    hideTypes:{
      type: Boolean,
      default: false
    },
    loading:{
      type: Boolean,
      default: false
    },
    files: Array,
  },
  computed:{
    ...mapGetters({
      user: 'app/getUser',
      isAdmin: 'app/isAdmin'
    }),
  },
  data(){
    return {
     documents:[],
      mimeTypes: [
        'image/bmp',
        'image/gif',
        'image/apng',
        'image/jpeg',
        'image/svg+xml',
        'image/x-icon',
        'image/png',
        'image/pict',
      ],
    }
  },
  methods:{
    clicked(){
      this.$emit('opened');
      this.$refs.filebox.click();
    },
    addText(){
      this.$emit('newText')
    },
    filesChange(fieldName, fileList) {
      const vm = this;
      if (!fileList.length) return;

      Array.from(Array(fileList.length).keys())
        .map(x => {
          //vm.formData.append(fieldName, fileList[x], fileList[x].name);


          let url = vm.getImage(fileList[x]);
          vm.files.push({
            name: fileList[x].name,
            description: '',
            url: url,
            thumbUrl: url,
            image: fileList[x],
            edit: false,
            deleted: false,
            documentType: 1
          });
        });
      vm.$emit('change');

      /*
      let data = new FormData();
      fileList.forEach(x => {
        data.append('photos', x.image, x.name);
      });

      //TODO: Create the document add message
      const model = {studentToken: vm.user.identifier, photoMetadata:[]};
      data.append('model', JSON.stringify(model));

      httpClient.post('/documents/create', data)
        .then(function(response){
          if(response.data.success){
            vm.$root.$emit('saveSuccess', {message: 'Documents Added'});
            vm.$emit('changed');
          }else{
            vm.$root.$emit('saveFailed', {message: response.data.message});
            vm.saving = false;
          }

        })
        .catch(function(error){
          console.log(error);
          vm.$root.$emit('saveFailed', {message: 'We apologize, an error has occurred. Please try again later.'});
          vm.saving = false;
        });
*/

    },
    getImage(file){
      const urlCreator = window.URL || window.webkitURL;

      let imageUrl = '';
      if (this.mimeTypes.includes(file.type)) {
        imageUrl = urlCreator.createObjectURL(file)
      } else {
        imageUrl = this.unknownFileImage
      }

      return imageUrl;
    },
  }
}
</script>

<style scoped>
.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 150px;
  position: absolute;
  cursor: pointer;
  left: -20000px;
}
</style>

import {mapGetters} from "vuex";

export default{
  data(){
    return {
      prevRoute: null
    }
  },
  computed:{
    ...mapGetters({
      user: 'app/getUser',
      isAdmin: 'app/isAdmin'
    }),
  },
  methods:{
    goBack(){
      const vm = this;
      if(vm.prevRoute != null ){
        if(vm.prevRoute.name === 'login'){
          vm.goDashboard();
        }else{
          vm.$router.go(-1);
        }
      }else{
        vm.goDashboard();
      }
    },
    goDashboard(){
      const vm = this;
      if(vm.isAdmin){
        vm.$router.push('/admin');
      }else{
        vm.$router.push('/');
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
}

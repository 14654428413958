<template>
  <div>
    <v-divider light></v-divider>
    <v-row style="margin-top:10px; margin-bottom:10px;">
      <v-col v-for="(f,idx) in folders" :key="idx" @click="selectFolder(f)" @drop="onDrop($event, f)" @dragover.prevent @dragenter.prevent>
        <div v-if="f === currentFolder" class='selectedFolder'>
          <v-icon v-if="f === currentFolder" :color="getFolderColor(f)" size="30" >mdi-folder-check</v-icon> {{f}}
        </div>
        <div v-if="f !== currentFolder" class="folder">
          <v-icon v-if="f !== currentFolder" :color="getFolderColor(f)" size="30" >mdi-folder</v-icon> {{f}}
        </div>
      </v-col>
    </v-row>
    <v-divider light></v-divider>
    <div style="margin-bottom:20px;"></div>
  </div>
</template>

<script>
import folderJson from './folders.json';

export default {
  name: "FolderSelector",
  data(){
    return {
      folders:folderJson,
      currentFolder: 'Spring 2022 Documents',
    }
  },
  methods:{
    onDrop(evt, item){
      const fileId = evt.dataTransfer.getData('fileId');
      const dropEvent = {
        id: fileId,
        folder: item
      };

      console.log(dropEvent);
      this.$emit('documentDropped', dropEvent);

    },
    getFolderColor(folder){
      return folder === this.currentFolder ? '#A83540' : 'default';
    },
    selectFolder(f){
      this.currentFolder = f;
      this.$root.$emit('folderselection', f);
    },
  },
  mounted(){
    const vm = this;
    vm.$nextTick(() => {vm.selectFolder(this.currentFolder);});

  }
}
</script>

<style scoped>
  .selectedFolder{
    color: #A83540;
    font-weight: bold;
    cursor: pointer;
  }

  .folder{
    cursor: pointer;
  }

  .folder:hover{
    color: #A83540;
  }

  .folder:hover .v-icon{
    color: #A83540;
  }



</style>

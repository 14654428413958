<template>
  <div>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-row>
      <v-col cols="6" md="4">
        <v-select v-model="service.organizationId"
                  :items="organizations"
                  item-text="name"
                  item-value="id"
                  label="Organization"
                  required
                  :rules="[v => !!v || 'Organization is required']"
                  @change="organizationChanged"
                  outlined
                  dense></v-select>
      </v-col>

      <v-col cols="6" md="4">
        <v-select v-model="service.contactId"
                  :items="contacts"
                  :item-text="concatName"
                  item-value="id"
                  label="Contact"
                  outlined
                  dense></v-select>
      </v-col>

    </v-row>

    <v-row>
      <v-col cols="3" md="2">
        <v-menu ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="formatDate"
                          label="Service Date"
                          hint="MM/DD/YYYY format"
                          persistent-hint
                          v-bind="attrs"
                          v-on="on"
                          autocomplete="off"
                          required
                          :rules="[v => !!v || 'Service Date is required']"
                          outlined
                          dense></v-text-field>
          </template>
          <v-date-picker v-model="service.serviceDate"
                         required
                         no-title
                         label="Service Date"
                         @input="menu1 = false"
                         outlined
                         dense></v-date-picker>
        </v-menu>
      </v-col>


      <v-col cols="3" md="2">
        <v-dialog
          ref="dialogStart"
          v-model="modalStartTime"
          :return-value.sync="time"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
             :value="formatTime"
              label="Service Start Time"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              dense
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="modalStartTime"
            v-model="service.serviceTime"
            :max="service.serviceEndTime"
            @change="serviceDuration"
            full-width
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="modalStartTime = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialogStart.save(time)"
            >
              OK
            </v-btn>
          </v-time-picker>
        </v-dialog>
      </v-col>

      <v-col cols="3" md="2">
        <v-dialog
          ref="dialogEnd"
          v-model="modalEndTime"
          :return-value.sync="endTime"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formatEndTime"
              label="Service End Time"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              dense
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="modalEndTime"
            v-model="service.serviceEndTime"
            :min="service.serviceTime"
            @change="serviceDuration"
            full-width
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="modalEndTime = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialogEnd.save(endTime)"
            >
              OK
            </v-btn>
          </v-time-picker>
        </v-dialog>
      </v-col>


      <v-col cols="3" md="2">
        <v-text-field outlined dense v-model="service.hours" :rules="[numberRule]" label="Hours" ></v-text-field>


      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" md="4" sm="4" xs="6">
        <v-select v-model="service.categoryId"
                  :items="categories"
                  item-text="name"
                  item-value="id"
                  label="Category"
                  required
                  :rules="[v => !!v || 'Category is required']"
                  outlined
                  dense></v-select>
      </v-col>

      <v-col cols="6" md="4" sm="4" xs="6">
        <v-select v-model="service.secondaryCategoryId"
                  :items="categories"
                  :clearable="true"
                  item-text="name"
                  item-value="id"
                  label="Secondary Category (optional)"
                  outlined
                  dense></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" md="4">
        <v-radio-group v-model="service.direct" row>
          <template v-slot:label>
            <div>Service Type:</div>
          </template>
          <v-radio label="Direct" :value="true"></v-radio>
          <v-radio label="Indirect" :value="false"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="8">
        <editor v-model="service.description"  placeholder="Service Description" required></editor>
        <div v-if="!descriptionRule" style="margin-top:5px; padding-left:10px;">
          <div class="v-text-field__details">
            <div class="v-messages theme--light error--text" role="alert">
              <div class="v-messages__wrapper">
                <div class="v-messages__message">Service Description is required</div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>



  </v-form>
  </div>
</template>

<script>
import Editor from '@/components/text/Editor.vue'
import _ from "lodash";
import { DateTime } from 'luxon';
export default {
  name: "ServiceDetails",
  components:{Editor},
  props:["service", "organizations", "categories"],
  data(){
    return {
      valid: true,
      menu1: false,
      modalStartTime: false,
      modalEndTime: false,
      time: null,
      endTime: null,
      contacts:[],
      numberRule: v => {
        if(v === undefined) return 'undefined';
        //if (!v.trim()) return true;
        if (!isNaN(parseFloat(v)) && v >= 0 && v <= 1000) return true;
        return 'Number has to be between 0 and 1000';
      },
      descriptionRule: true
    }
  },
  computed:{
    formatDate(){
      return this.$luxon(this.service.serviceDate, "date_short");
    },
    formatTime(){
      const vm = this;

      const timeString12hr = new Date('1970-01-01T' + vm.service.serviceTime + 'Z')
        .toLocaleTimeString('en-US',
          {timeZone:'UTC',hour12:true,hour:'numeric',minute:'numeric'}
        );

      return timeString12hr;
    },
    formatEndTime(){
      const vm = this;

      const timeString12hr = new Date('1970-01-01T' + vm.service.serviceEndTime + 'Z')
        .toLocaleTimeString('en-US',
          {timeZone:'UTC',hour12:true,hour:'numeric',minute:'numeric'}
        );

      return timeString12hr;
    },

  },
  methods: {
    round(number, decimalPlaces)  {
      const factorOfTen = Math.pow(10, decimalPlaces)
      return Math.round(number * factorOfTen) / factorOfTen
    },
    serviceDuration(){

      const vm = this;
      const start = DateTime.fromISO (`2022-01-01T${vm.service.serviceTime}:00`);
      const end = DateTime.fromISO(`2022-01-01T${vm.service.serviceEndTime}:00`);

      const diff = end.diff(start, ["hours", "minutes"]);
      let hours = diff.values.hours + (diff.values.minutes/60);

      if(hours > 0){
        vm.service.hours = vm.round(hours,2);
      }else{
        //some sort of notice
      }



    },
    concatName(contact){
      return `${contact.firstName} ${contact.lastName}`;
    },
    organizationChanged(){
      const vm = this;
      const org = _.find(vm.organizations, {'id': vm.service.organizationId});

      if(org !== undefined){
        vm.contacts = org.contacts;
      }

    },
    validate(){
      this.descriptionRule = this.service.description !== undefined
        && this.service.description.trim() !== ''
        && this.service.description.trim() !== '<p></p>';

      let formValid = this.$refs.form.validate();
      return this.descriptionRule && formValid;
    }
  },
  mounted() {
  },
  watch:{
    service: {
      handler(val){
        if(this.service.hours !== undefined) {
          this.service.hours = this.service.hours.toString();
        }

        this.organizationChanged();
      },
       deep:false
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <div>
      <folder-selector v-if="!hideFolderList" @documentDropped="documentMoved" class="folder-selector"></folder-selector>
      <v-row v-if="filesInCurrentFolder.length === 0" align="stretch" class="">
        <v-col cols="12">

          <div class="empty-folder-text"><v-icon color="#A83540">mdi-folder-alert-outline</v-icon> This folder is empty</div>
        </v-col>
      </v-row>
      <v-row v-else align="stretch" class="after-fixed">
        <v-col cols="12" md="3" v-for="(file, idx) in filesInCurrentFolder" :key="idx">

          <v-hover v-slot="{ hover }">

            <v-card draggable="true" @dragstart="startDrag($event, file)" class="drag-el">
            <v-card-title>{{file.name}}</v-card-title>
              <v-card-text>

                <div v-if="file.documentType === 1">
                  <v-img :src="file.thumbUrl"></v-img>
                  {{file.dateString}}
                  <br/>

                  <div v-if="!hideFolderList" v-html="file.description" ></div>
                  <div v-if="hideFolderList"><v-icon>mdi-folder</v-icon> {{file.folder}}</div>
                </div>

                <div v-if="file.documentType === 2">
                  <v-img :src="file.thumbUrl" width="300"></v-img>
                  {{file.dateString}}
                  <br/>
                  <div v-if="!hideFolderList" v-html="descriptionSample(file.description)" ></div>
                  <div v-if="hideFolderList"><v-icon>mdi-folder</v-icon> {{file.folder}}</div>
                </div>




                <v-overlay :value="hover" :absolute=true>
                  <v-sheet class="pa-2" color="white" center>
                    <v-btn v-if="canDownload(file)" color="primary" text @click="download(file)"><v-icon>mdi-download</v-icon> Download</v-btn>
                    <v-btn text color="primary" @click="editImage(file)"><v-icon>mdi-pencil</v-icon> Edit</v-btn>
                    <v-btn text color="error" @click="remove(file)"><v-icon>mdi-delete</v-icon> Delete</v-btn>
                  </v-sheet>
                </v-overlay>
              </v-card-text>
            </v-card>

          </v-hover>
        </v-col>
      </v-row>
    </div>

    <v-dialog
      v-model="editImageDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >

        <v-toolbar-title>Document Properties</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            dark
            text
            @click="cancelUpdate"
          >
            Cancel
          </v-btn>
          <v-btn
            dark
            text
            @click="updateImage"
          >
            Save My Changes
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-row>
        <v-col cols="12" md="3">
          <v-img :src="selectedImage.thumbUrl" class="ma-3"></v-img>
        </v-col>
        <v-col cols="12" md="4" v-if="selectedImage.documentType === 1">
          <v-row>
            <v-col cols="12">
              <div style="margin-top:20px;">
              <v-select label="Folder" :items="folders" v-model="selectedImage.folder" dense></v-select>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="selectedImage.description"
                label="Description"
                class="ma-5"
              ></v-textarea>
            </v-col>
          </v-row>

        </v-col>

        <v-col v-if="selectedImage.documentType === 2">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="selectedImage.name" label="Name" placeholder="Name" class="ml-5 mt-5" outlined dense></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <editor v-model="selectedImage.description"  placeholder="Document Text" validation-message="Field is required" class="ml-5"></editor>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

    </v-card>
    </v-dialog>



  </div>
</template>

<script>
import folderJson from './folders.json';
import {mapGetters} from "vuex";
import Editor from "@/components/text/Editor";
import FolderSelector from "@/components/file/FolderSelector";

export default {
  name: "DocumentList",
  components:{FolderSelector, Editor},
  props: {
    files: Array,
    hideFolderList: Boolean
  },
  data(){
    return {
      dragging: false,
      folders: folderJson,
      currentFolder: null,
      editImageDialog: false,
      selectedImage: {},
      originalDescription: '',

    }
  },
  computed:{
    ...mapGetters({
      user: 'app/getUser',
      isAdmin: 'app/isAdmin'
    }),
    filesInCurrentFolder(){
      const vm = this;
      if(vm.hideFolderList === true){
        return vm.notDeletedFiles;
      }


      const f = vm.notDeletedFiles.filter((f) => {return f.folder === vm.currentFolder;});
      return f;
    },
    notDeletedFiles(){
      if(this.files !== undefined){
        return this.files.filter(function(item){return item.deleted === false});
      }
      else{
        return [];
      }
    },
  },
  methods:{
    startDrag(evt, file){
      this.dragging = true;
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('fileId', file.id)
    },
    documentMoved(args){
      const file = this.files.find(({id}) => id === parseInt(args.id));
      if(file !== undefined){
        console.log('hi there');
        file.folder = args.folder;
        this.$emit('change');
      }

    },
    canDownload(file){
      return file.id !== undefined && file.id !== 0 && file.documentType === 1;
    },
    editImage(file){
      this.selectedImage = file;
      this.originalDescription = file.description;
      this.editImageDialog = true;
    },
    download(file){
      console.log(file);
      this.$emit('download', file);
    },
    remove(file){
      this.$emit('delete', file);
    },
    updateImage(){
      this.$emit('change', this.selectedImage)
      this.editImageDialog = false;
    },
    cancelUpdate(){
      this.selectedImage.description = this.originalDescription;
      this.editImageDialog = false;
    },
    descriptionSample(text){
      if(text.length <= 30) return text;
      return text.substring(0,30) + '...';
    },
    selectFolder(folder){
     this.currentFolder = folder;
    }
  },
  mounted(){
    const vm = this;
    vm.$root.$on('folderselection', vm.selectFolder);
  },
  beforeDestroy() {
    const vm = this;
    vm.$root.$off('folderselection', vm.selectFolder);
  }
}
</script>

<style scoped>
.v-card__title{
  font-size:15px;
}
.folder-selector{
  width: 100%;
  position: sticky;
  top: 100px;
  z-index: 199;
  background-color:white;
}
.after-fixed{
  margin-top:50px;
}


.drag-el {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px;
}

.v-image{
  height:250px;

}
.v-image >>> .v-image__image{
  background-position: top center !important;
}
.v-card__text{
  height:325px;
}

.empty-folder-text{
  color: #A83540;
  font-size: 16px;
}
</style>
